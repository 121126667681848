import i18n from 'i18next';
import "./../i18n";

export const INSURANCE_CFE_OPTIONS = [
    { value: 'YES', label: i18n.t("global_variables_yes") },
    { value: 'NO', label: i18n.t("global_variables_no") },
    { value: 'NOT_KNOWN', label: i18n.t("global_variables_not_know") }
];


export const NATIONALITY_OPTIONS = [
    { value: "Afrique du Sud",  label: i18n.t("Afrique du Sud")},
    { value: "Afghanistan",  label: i18n.t("Afghanistan")},
    { value: "Albanie",  label: i18n.t("Albanie")},
    { value: "Algérie",  label: i18n.t("Algérie")},
    { value: "Allemagne",  label: i18n.t("Allemagne")},
    { value: "Andorre",  label: i18n.t("Andorre")},
    { value: "Angola",  label: i18n.t("Angola")},
    { value: "Arabie saoudite",  label: i18n.t("Arabie saoudite")},
    { value: "Argentine",  label: i18n.t("Argentine")},
    { value: "Arménie",  label: i18n.t("Arménie")},
    { value: "Australie",  label: i18n.t("Australie")},
    { value: "Autriche",  label: i18n.t("Autriche")},
    { value: "Azerbaïdjan",  label: i18n.t("Azerbaïdjan")},
    { value: "Bahamas",  label: i18n.t("Bahamas")},
    { value: "Bahreïn",  label: i18n.t("Bahreïn")},
    { value: "Bangladesh",  label: i18n.t("Bangladesh")},
    { value: "Barbade",  label: i18n.t("Barbade")},
    { value: "Bélarus",  label: i18n.t("Bélarus")},
    { value: "Belgique",  label: i18n.t("Belgique")},
    { value: "Bélize",  label: i18n.t("Bélize")},
    { value: "Bénin",  label: i18n.t("Bénin")},
    { value: "Bhoutan",  label: i18n.t("Bhoutan")},
    { value: "Birmanie",  label: i18n.t("Birmanie")},
    { value: "Bolivie",  label: i18n.t("Bolivie")},
    { value: "Bosnie-Herzégovine",  label: i18n.t("Bosnie-Herzégovine")},
    { value: "Botswana",  label: i18n.t("Botswana")},
    { value: "Brésil",  label: i18n.t("Brésil")},
    { value: "Brunéi Darussalam",  label: i18n.t("Brunéi Darussalam")},
    { value: "Bulgarie",  label: i18n.t("Bulgarie")},
    { value: "Burkina Faso",  label: i18n.t("Burkina Faso")},
    { value: "Burundi",  label: i18n.t("Burundi")},
    { value: "Cameroun",  label: i18n.t("Cameroun")},
    { value: "Canada",  label: i18n.t("Canada")},
    { value: 'Cambodge', label: i18n.t("Cambodge")},
    { value: "Chili",  label: i18n.t("Chili")},
    { value: "Chine",  label: i18n.t("Chine")},
    { value: "Chypre",  label: i18n.t("Chypre")},
    { value: "Colombie",  label: i18n.t("Colombie")},
    { value: "Comores",  label: i18n.t("Comores")},
    { value: "Congo",  label: i18n.t("Congo")},
    { value: "Corée du Nord",  label: i18n.t("Corée du Nord")},
    { value: "Corée du Sud",  label: i18n.t("Corée du Sud")},
    { value: "Costa Rica",  label: i18n.t("Costa Rica")},
    { value: "Côte d’Ivoire",  label: i18n.t("Côte d’Ivoire")},
    { value: "Croatie",  label: i18n.t("Croatie")},
    { value: "Cuba",  label: i18n.t("Cuba")},
    { value: "Danemark",  label: i18n.t("Danemark")},
    { value: "Djibouti",  label: i18n.t("Djibouti")},
    { value: "Dominique",  label: i18n.t("Dominique")},
    { value: "Égypte",  label: i18n.t("Égypte")},
    { value: "Émirats arabes unis",  label: i18n.t("Émirats arabes unis")},
    { value: "Équateur",  label: i18n.t("Équateur")},
    { value: "Érythrée",  label: i18n.t("Érythrée")},
    { value: "Espagne",  label: i18n.t("Espagne")},
    { value: "Estonie",  label: i18n.t("Estonie")},
    { value: "États-Unis d’Amérique",  label: i18n.t("États-Unis d’Amérique")},
    { value: "Éthiopie",  label: i18n.t("Éthiopie")},
    { value: "Fidji",  label: i18n.t("Fidji")},
    { value: "Finlande",  label: i18n.t("Finlande")},
    { value: "France",  label: i18n.t("France")},
    { value: "Gabon",  label: i18n.t("Gabon")},
    { value: "Gambie",  label: i18n.t("Gambie")},
    { value: "Géorgie",  label: i18n.t("Géorgie")},
    { value: "Ghana",  label: i18n.t("Ghana")},
    { value: "Grèce",  label: i18n.t("Grèce")},
    { value: "Grenade",  label: i18n.t("Grenade")},
    { value: "Guatemala",  label: i18n.t("Guatemala")},
    { value: "Guinée",  label: i18n.t("Guinée")},
    { value: "Guinée-Bissau",  label: i18n.t("Guinée-Bissau")},
    { value: "Guinée équatoriale",  label: i18n.t("Guinée équatoriale")},
    { value: "Guyana",  label: i18n.t("Guyana")},
    { value: "Haïti",  label: i18n.t("Haïti")},
    { value: "Honduras",  label: i18n.t("Honduras")},
    { value: "Hong Kong",  label: i18n.t("Hong Kong")},
    { value: "Hongrie",  label: i18n.t("Hongrie")},
    { value: "Île Maurice",  label: i18n.t("Île Maurice")},
    { value: "Îles Marshall",  label: i18n.t("Îles Marshall")},
    { value: "Îles Salomon",  label: i18n.t("Îles Salomon")},
    { value: "Inde",  label: i18n.t("Inde")},
    { value: "Indonésie",  label: i18n.t("Indonésie")},
    { value: "Iran",  label: i18n.t("Iran")},
    { value: "Irak",  label: i18n.t("Irak")},
    { value: "Irlande",  label: i18n.t("Irlande")},
    { value: "Islande",  label: i18n.t("Islande")},
    { value: "Israël",  label: i18n.t("Israël")},
    { value: "Italie",  label: i18n.t("Italie")},
    { value: "Jamaïque",  label: i18n.t("Jamaïque")},
    { value: "Japon",  label: i18n.t("Japon")},
    { value: "Jordanie",  label: i18n.t("Jordanie")},
    { value: "Kazakhstan",  label: i18n.t("Kazakhstan")},
    { value: "Kenya",  label: i18n.t("Kenya")},
    { value: "Kiribati",  label: i18n.t("Kiribati")},
    { value: "Kirghizistan",  label: i18n.t("Kirghizistan")},
    { value: "Kosovo",  label: i18n.t("Kosovo")},
    { value: "Koweït",  label: i18n.t("Koweït")},
    { value: "Laos",  label: i18n.t("Laos")},
    { value: "Lesotho",  label: i18n.t("Lesotho")},
    { value: "Lettonie",  label: i18n.t("Lettonie")},
    { value: "Liban",  label: i18n.t("Liban")},
    { value: "Libéria",  label: i18n.t("Libéria")},
    { value: "Libye",  label: i18n.t("Libye")},
    { value: "Liechtenstein",  label: i18n.t("Liechtenstein")},
    { value: "Lituanie",  label: i18n.t("Lituanie")},
    { value: "Luxembourg",  label: i18n.t("Luxembourg")},
    { value: "Macédoine",  label: i18n.t("Macédoine")},
    { value: "Madagascar",  label: i18n.t("Madagascar")},
    { value: "Malaisie",  label: i18n.t("Malaisie")},
    { value: "Malawi",  label: i18n.t("Malawi")},
    { value: "Maldives",  label: i18n.t("Maldives")},
    { value: "Mali",  label: i18n.t("Mali")},
    { value: "Malte",  label: i18n.t("Malte")},
    { value: "Maroc",  label: i18n.t("Maroc")},
    { value: "Mauritanie",  label: i18n.t("Mauritanie")},
    { value: "Mexique",  label: i18n.t("Mexique")},
    { value: "Micronésie",  label: i18n.t("Micronésie")},
    { value: "Moldavie",  label: i18n.t("Moldavie")},
    { value: "Monaco",  label: i18n.t("Monaco")},
    { value: "Monténégro",  label: i18n.t("Monténégro")},
    { value: "Mozambique",  label: i18n.t("Mozambique")},
    { value: "Namibie",  label: i18n.t("Namibie")},
    { value: "Nauru",  label: i18n.t("Nauru")},
    { value: "Népal",  label: i18n.t("Népal")},
    { value: "Nicaragua",  label: i18n.t("Nicaragua")},
    { value: "Niger",  label: i18n.t("Niger")},
    { value: "Nigéria",  label: i18n.t("Nigéria")},
    { value: "Norvège",  label: i18n.t("Norvège")},
    { value: "Nouvelle-Zélande",  label: i18n.t("Nouvelle-Zélande")},
    { value: "Oman",  label: i18n.t("Oman")},
    { value: "Ouganda",  label: i18n.t("Ouganda")},
    { value: "Ouzbékistan",  label: i18n.t("Ouzbékistan")},
    { value: "Pakistan",  label: i18n.t("Pakistan")},
    { value: "Palaos",  label: i18n.t("Palaos")},
    { value: "Panamá",  label: i18n.t("Panamá")},
    { value: "Papouasie-Nouvelle-Guinée",  label: i18n.t("Papouasie-Nouvelle-Guinée")},
    { value: "Paraguay",  label: i18n.t("Paraguay")},
    { value: "Pays-Bas",  label: i18n.t("Pays-Bas")},
    { value: "Pérou",  label: i18n.t("Pérou")},
    { value: "Philippines",  label: i18n.t("Philippines")},
    { value: "Pologne",  label: i18n.t("Pologne")},
    { value: "Polynésie Française",  label: i18n.t("Polynésie Française")},
    { value: "Porto Rico",  label: i18n.t("Porto Rico")},
    { value: "Portugal",  label: i18n.t("Portugal")},
    { value: "Qatar",  label: i18n.t("Qatar")},
    { value: "République centrafricaine",  label: i18n.t("République centrafricaine")},
    { value: "République démocratique du Congo",  label: i18n.t("République démocratique du Congo")},
    { value: "République dominicaine",  label: i18n.t("République dominicaine")},
    { value: "République tchèque",  label: i18n.t("République tchèque")},
    { value: "Roumanie",  label: i18n.t("Roumanie")},
    { value: "Royaume-Uni",  label: i18n.t("Royaume-Uni")},
    { value: "Russie",  label: i18n.t("Russie")},
    { value: "Rwanda",  label: i18n.t("Rwanda")},
    { value: "Saint-Kitts-et-Nevis",  label: i18n.t("Saint-Kitts-et-Nevis")},
    { value: "Sainte-Lucie",  label: i18n.t("Sainte-Lucie")},
    { value: "Saint-Marin",  label: i18n.t("Saint-Marin")},
    { value: "Saint-Vincent-et-les Grenadines",  label: i18n.t("Saint-Vincent-et-les Grenadines")},
    { value: "Saint-Siège",  label: i18n.t("Saint-Siège")},
    { value: "Salvador",  label: i18n.t("Salvador")},
    { value: "Samoa",  label: i18n.t("Samoa")},
    { value: "Sao Tomé-et-Principe",  label: i18n.t("Sao Tomé-et-Principe")},
    { value: "Sénégal",  label: i18n.t("Sénégal")},
    { value: "Serbie",  label: i18n.t("Serbie")},
    { value: "Seychelles",  label: i18n.t("Seychelles")},
    { value: "Sierra Leone",  label: i18n.t("Sierra Leone")},
    { value: "Singapour",  label: i18n.t("Singapour")},
    { value: "Slovaquie",  label: i18n.t("Slovaquie")},
    { value: "Slovénie",  label: i18n.t("Slovénie")},
    { value: "Somalie",  label: i18n.t("Somalie")},
    { value: "Soudan",  label: i18n.t("Soudan")},
    { value: "Soudan du Sud",  label: i18n.t("Soudan du Sud")},
    { value: "Sri Lanka",  label: i18n.t("Sri Lanka")},
    { value: "Suède",  label: i18n.t("Suède")},
    { value: "Suisse",  label: i18n.t("Suisse")},
    { value: "Suriname",  label: i18n.t("Suriname")},
    { value: "Swaziland",  label: i18n.t("Swaziland")},
    { value: "Syrie",  label: i18n.t("Syrie")},
    { value: "Tadjikistan",  label: i18n.t("Tadjikistan")},
    { value: "Taïwan",  label: i18n.t("Taïwan")},
    { value: "Tanzanie",  label: i18n.t("Tanzanie")},
    { value: "Tchad",  label: i18n.t("Tchad")},
    { value: "Thaïlande",  label: i18n.t("Thaïlande")},
    { value: "Timor-Oriental",  label: i18n.t("Timor-Oriental")},
    { value: "Togo",  label: i18n.t("Togo")},
    { value: "Tonga",  label: i18n.t("Tonga")},
    { value: "Trinité-et-Tobago",  label: i18n.t("Trinité-et-Tobago")},
    { value: "Tunisie",  label: i18n.t("Tunisie")},
    { value: "Turquie",  label: i18n.t("Turquie")},
    { value: "Turkménistan",  label: i18n.t("Turkménistan")},
    { value: "Tuvalu",  label: i18n.t("Tuvalu")},
    { value: "Ukraine",  label: i18n.t("Ukraine")},
    { value: "Uruguay",  label: i18n.t("Uruguay")},
    { value: "Vanuatu",  label: i18n.t("Vanuatu")},
    { value: "Venezuela",  label: i18n.t("Venezuela")},
    { value: "Vietnam",  label: i18n.t("Vietnam")},
    { value: "Yémen",  label: i18n.t("Yémen")},
    { value: "Zambie",  label: i18n.t("Zambie")},
    { value: "Zimbabwe",  label: i18n.t("Zimbabwe")}

];


export const AGE_OPTIONS = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31'},
    { value: '32', label: '32'},
    { value: '33', label: '33'},
    { value: '34', label: '34'},
    { value: '35', label: '35'},
    { value: '36', label: '36'},
    { value: '37', label: '37'},
    { value: '38', label: '38'},
    { value: '39', label: '39'},
    { value: '40', label: '40'},
    { value: '41', label: '41'},
    { value: '42', label: '42'},
    { value: '43', label: '43'},
    { value: '44', label: '44'},
    { value: '45', label: '45'},
    { value: '46', label: '46'},
    { value: '47', label: '47'},
    { value: '48', label: '48'},
    { value: '49', label: '49'},
    { value: '50', label: '50'},
    { value: '51', label: '51'},
    { value: '52', label: '52'},
    { value: '53', label: '53'},
    { value: '54', label: '54'},
    { value: '55', label: '55'},
    { value: '56', label: '56'},
    { value: '57', label: '57'},
    { value: '58', label: '58'},
    { value: '59', label: '59'},
    { value: '60', label: '60'},
    { value: '61', label: '61'},
    { value: '62', label: '62'},
    { value: '63', label: '63'},
    { value: '64', label: '64'},
    { value: '65', label: '65'},
    { value: '66', label: '66'},
    { value: '67', label: '67'},
    { value: '68', label: '68'},
    { value: '69', label: '69'},
    { value: '70', label: '70'},
    { value: '71', label: '71'},
    { value: '72', label: '72'},
    { value: '73', label: '73'},
    { value: '74', label: '74'},
    { value: '75', label: '75'},
];



export const DESTINATION_COUNTRY_OPTIONS = [
    { value: "Afrique du Sud",  label: i18n.t("Afrique du Sud")},
    { value: "Afghanistan",  label: i18n.t("Afghanistan")},
    { value: "Albanie",  label: i18n.t("Albanie")},
    { value: "Algérie",  label: i18n.t("Algérie")},
    { value: "Allemagne",  label: i18n.t("Allemagne")},
    { value: "Andorre",  label: i18n.t("Andorre")},
    { value: "Angola",  label: i18n.t("Angola")},
    { value: "Arabie saoudite",  label: i18n.t("Arabie saoudite")},
    { value: "Argentine",  label: i18n.t("Argentine")},
    { value: "Arménie",  label: i18n.t("Arménie")},
    { value: "Australie",  label: i18n.t("Australie")},
    { value: "Autriche",  label: i18n.t("Autriche")},
    { value: "Azerbaïdjan",  label: i18n.t("Azerbaïdjan")},
    { value: "Bahamas",  label: i18n.t("Bahamas")},
    { value: "Bahreïn",  label: i18n.t("Bahreïn")},
    { value: "Bangladesh",  label: i18n.t("Bangladesh")},
    { value: "Barbade",  label: i18n.t("Barbade")},
    { value: "Bélarus",  label: i18n.t("Bélarus")},
    { value: "Belgique",  label: i18n.t("Belgique")},
    { value: "Bélize",  label: i18n.t("Bélize")},
    { value: "Bénin",  label: i18n.t("Bénin")},
    { value: "Bhoutan",  label: i18n.t("Bhoutan")},
    { value: "Birmanie",  label: i18n.t("Birmanie")},
    { value: "Bolivie",  label: i18n.t("Bolivie")},
    { value: "Bosnie-Herzégovine",  label: i18n.t("Bosnie-Herzégovine")},
    { value: "Botswana",  label: i18n.t("Botswana")},
    { value: "Brésil",  label: i18n.t("Brésil")},
    { value: "Brunéi Darussalam",  label: i18n.t("Brunéi Darussalam")},
    { value: "Bulgarie",  label: i18n.t("Bulgarie")},
    { value: "Burkina Faso",  label: i18n.t("Burkina Faso")},
    { value: "Burundi",  label: i18n.t("Burundi")},
    { value: "Cameroun",  label: i18n.t("Cameroun")},
    { value: "Canada",  label: i18n.t("Canada")},
    { value: 'Cambodge', label: i18n.t("Cambodge")},
    { value: "Chili",  label: i18n.t("Chili")},
    { value: "Chine",  label: i18n.t("Chine")},
    { value: "Chypre",  label: i18n.t("Chypre")},
    { value: "Colombie",  label: i18n.t("Colombie")},
    { value: "Comores",  label: i18n.t("Comores")},
    { value: "Congo",  label: i18n.t("Congo")},
    { value: "Corée du Nord",  label: i18n.t("Corée du Nord")},
    { value: "Corée du Sud",  label: i18n.t("Corée du Sud")},
    { value: "Costa Rica",  label: i18n.t("Costa Rica")},
    { value: "Côte d’Ivoire",  label: i18n.t("Côte d’Ivoire")},
    { value: "Croatie",  label: i18n.t("Croatie")},
    { value: "Cuba",  label: i18n.t("Cuba")},
    { value: "Danemark",  label: i18n.t("Danemark")},
    { value: "Djibouti",  label: i18n.t("Djibouti")},
    { value: "Dominique",  label: i18n.t("Dominique")},
    { value: "Égypte",  label: i18n.t("Égypte")},
    { value: "Émirats arabes unis",  label: i18n.t("Émirats arabes unis")},
    { value: "Équateur",  label: i18n.t("Équateur")},
    { value: "Érythrée",  label: i18n.t("Érythrée")},
    { value: "Espagne",  label: i18n.t("Espagne")},
    { value: "Estonie",  label: i18n.t("Estonie")},
    { value: "États-Unis d’Amérique",  label: i18n.t("États-Unis d’Amérique")},
    { value: "Éthiopie",  label: i18n.t("Éthiopie")},
    { value: "Fidji",  label: i18n.t("Fidji")},
    { value: "Finlande",  label: i18n.t("Finlande")},
    { value: "France",  label: i18n.t("France")},
    { value: "Gabon",  label: i18n.t("Gabon")},
    { value: "Gambie",  label: i18n.t("Gambie")},
    { value: "Géorgie",  label: i18n.t("Géorgie")},
    { value: "Ghana",  label: i18n.t("Ghana")},
    { value: "Grèce",  label: i18n.t("Grèce")},
    { value: "Grenade",  label: i18n.t("Grenade")},
    { value: "Guatemala",  label: i18n.t("Guatemala")},
    { value: "Guinée",  label: i18n.t("Guinée")},
    { value: "Guinée-Bissau",  label: i18n.t("Guinée-Bissau")},
    { value: "Guinée équatoriale",  label: i18n.t("Guinée équatoriale")},
    { value: "Guyana",  label: i18n.t("Guyana")},
    { value: "Haïti",  label: i18n.t("Haïti")},
    { value: "Honduras",  label: i18n.t("Honduras")},
    { value: "Hong Kong",  label: i18n.t("Hong Kong")},
    { value: "Hongrie",  label: i18n.t("Hongrie")},
    { value: "Île Maurice",  label: i18n.t("Île Maurice")},
    { value: "Îles Marshall",  label: i18n.t("Îles Marshall")},
    { value: "Îles Salomon",  label: i18n.t("Îles Salomon")},
    { value: "Inde",  label: i18n.t("Inde")},
    { value: "Indonésie",  label: i18n.t("Indonésie")},
    { value: "Iran",  label: i18n.t("Iran")},
    { value: "Irak",  label: i18n.t("Irak")},
    { value: "Irlande",  label: i18n.t("Irlande")},
    { value: "Islande",  label: i18n.t("Islande")},
    { value: "Israël",  label: i18n.t("Israël")},
    { value: "Italie",  label: i18n.t("Italie")},
    { value: "Jamaïque",  label: i18n.t("Jamaïque")},
    { value: "Japon",  label: i18n.t("Japon")},
    { value: "Jordanie",  label: i18n.t("Jordanie")},
    { value: "Kazakhstan",  label: i18n.t("Kazakhstan")},
    { value: "Kenya",  label: i18n.t("Kenya")},
    { value: "Kiribati",  label: i18n.t("Kiribati")},
    { value: "Kirghizistan",  label: i18n.t("Kirghizistan")},
    { value: "Kosovo",  label: i18n.t("Kosovo")},
    { value: "Koweït",  label: i18n.t("Koweït")},
    { value: "Laos",  label: i18n.t("Laos")},
    { value: "Lesotho",  label: i18n.t("Lesotho")},
    { value: "Lettonie",  label: i18n.t("Lettonie")},
    { value: "Liban",  label: i18n.t("Liban")},
    { value: "Libéria",  label: i18n.t("Libéria")},
    { value: "Libye",  label: i18n.t("Libye")},
    { value: "Liechtenstein",  label: i18n.t("Liechtenstein")},
    { value: "Lituanie",  label: i18n.t("Lituanie")},
    { value: "Luxembourg",  label: i18n.t("Luxembourg")},
    { value: "Macédoine",  label: i18n.t("Macédoine")},
    { value: "Madagascar",  label: i18n.t("Madagascar")},
    { value: "Malaisie",  label: i18n.t("Malaisie")},
    { value: "Malawi",  label: i18n.t("Malawi")},
    { value: "Maldives",  label: i18n.t("Maldives")},
    { value: "Mali",  label: i18n.t("Mali")},
    { value: "Malte",  label: i18n.t("Malte")},
    { value: "Maroc",  label: i18n.t("Maroc")},
    { value: "Mauritanie",  label: i18n.t("Mauritanie")},
    { value: "Mexique",  label: i18n.t("Mexique")},
    { value: "Micronésie",  label: i18n.t("Micronésie")},
    { value: "Moldavie",  label: i18n.t("Moldavie")},
    { value: "Monaco",  label: i18n.t("Monaco")},
    { value: "Monténégro",  label: i18n.t("Monténégro")},
    { value: "Mozambique",  label: i18n.t("Mozambique")},
    { value: "Namibie",  label: i18n.t("Namibie")},
    { value: "Nauru",  label: i18n.t("Nauru")},
    { value: "Népal",  label: i18n.t("Népal")},
    { value: "Nicaragua",  label: i18n.t("Nicaragua")},
    { value: "Niger",  label: i18n.t("Niger")},
    { value: "Nigéria",  label: i18n.t("Nigéria")},
    { value: "Norvège",  label: i18n.t("Norvège")},
    { value: "Nouvelle-Zélande",  label: i18n.t("Nouvelle-Zélande")},
    { value: "Oman",  label: i18n.t("Oman")},
    { value: "Ouganda",  label: i18n.t("Ouganda")},
    { value: "Ouzbékistan",  label: i18n.t("Ouzbékistan")},
    { value: "Pakistan",  label: i18n.t("Pakistan")},
    { value: "Palaos",  label: i18n.t("Palaos")},
    { value: "Panamá",  label: i18n.t("Panamá")},
    { value: "Papouasie-Nouvelle-Guinée",  label: i18n.t("Papouasie-Nouvelle-Guinée")},
    { value: "Paraguay",  label: i18n.t("Paraguay")},
    { value: "Pays-Bas",  label: i18n.t("Pays-Bas")},
    { value: "Pérou",  label: i18n.t("Pérou")},
    { value: "Philippines",  label: i18n.t("Philippines")},
    { value: "Pologne",  label: i18n.t("Pologne")},
    { value: "Polynésie Française",  label: i18n.t("Polynésie Française")},
    { value: "Porto Rico",  label: i18n.t("Porto Rico")},
    { value: "Portugal",  label: i18n.t("Portugal")},
    { value: "Qatar",  label: i18n.t("Qatar")},
    { value: "République centrafricaine",  label: i18n.t("République centrafricaine")},
    { value: "République démocratique du Congo",  label: i18n.t("République démocratique du Congo")},
    { value: "République dominicaine",  label: i18n.t("République dominicaine")},
    { value: "République tchèque",  label: i18n.t("République tchèque")},
    { value: "Roumanie",  label: i18n.t("Roumanie")},
    { value: "Royaume-Uni",  label: i18n.t("Royaume-Uni")},
    { value: "Russie",  label: i18n.t("Russie")},
    { value: "Rwanda",  label: i18n.t("Rwanda")},
    { value: "Saint-Kitts-et-Nevis",  label: i18n.t("Saint-Kitts-et-Nevis")},
    { value: "Sainte-Lucie",  label: i18n.t("Sainte-Lucie")},
    { value: "Saint-Marin",  label: i18n.t("Saint-Marin")},
    { value: "Saint-Vincent-et-les Grenadines",  label: i18n.t("Saint-Vincent-et-les Grenadines")},
    { value: "Saint-Siège",  label: i18n.t("Saint-Siège")},
    { value: "Salvador",  label: i18n.t("Salvador")},
    { value: "Samoa",  label: i18n.t("Samoa")},
    { value: "Sao Tomé-et-Principe",  label: i18n.t("Sao Tomé-et-Principe")},
    { value: "Sénégal",  label: i18n.t("Sénégal")},
    { value: "Serbie",  label: i18n.t("Serbie")},
    { value: "Seychelles",  label: i18n.t("Seychelles")},
    { value: "Sierra Leone",  label: i18n.t("Sierra Leone")},
    { value: "Singapour",  label: i18n.t("Singapour")},
    { value: "Slovaquie",  label: i18n.t("Slovaquie")},
    { value: "Slovénie",  label: i18n.t("Slovénie")},
    { value: "Somalie",  label: i18n.t("Somalie")},
    { value: "Soudan",  label: i18n.t("Soudan")},
    { value: "Soudan du Sud",  label: i18n.t("Soudan du Sud")},
    { value: "Sri Lanka",  label: i18n.t("Sri Lanka")},
    { value: "Suède",  label: i18n.t("Suède")},
    { value: "Suisse",  label: i18n.t("Suisse")},
    { value: "Suriname",  label: i18n.t("Suriname")},
    { value: "Swaziland",  label: i18n.t("Swaziland")},
    { value: "Syrie",  label: i18n.t("Syrie")},
    { value: "Tadjikistan",  label: i18n.t("Tadjikistan")},
    { value: "Taïwan",  label: i18n.t("Taïwan")},
    { value: "Tanzanie",  label: i18n.t("Tanzanie")},
    { value: "Tchad",  label: i18n.t("Tchad")},
    { value: "Thaïlande",  label: i18n.t("Thaïlande")},
    { value: "Timor-Oriental",  label: i18n.t("Timor-Oriental")},
    { value: "Togo",  label: i18n.t("Togo")},
    { value: "Tonga",  label: i18n.t("Tonga")},
    { value: "Trinité-et-Tobago",  label: i18n.t("Trinité-et-Tobago")},
    { value: "Tunisie",  label: i18n.t("Tunisie")},
    { value: "Turquie",  label: i18n.t("Turquie")},
    { value: "Turkménistan",  label: i18n.t("Turkménistan")},
    { value: "Tuvalu",  label: i18n.t("Tuvalu")},
    { value: "Ukraine",  label: i18n.t("Ukraine")},
    { value: "Uruguay",  label: i18n.t("Uruguay")},
    { value: "Vanuatu",  label: i18n.t("Vanuatu")},
    { value: "Venezuela",  label: i18n.t("Venezuela")},
    { value: "Vietnam",  label: i18n.t("Vietnam")},
    { value: "Yémen",  label: i18n.t("Yémen")},
    { value: "Zambie",  label: i18n.t("Zambie")},
    { value: "Zimbabwe",  label: i18n.t("Zimbabwe")}

];


export const INSURANCE_DURATION_OPTIONS = [
    // { value: '<3', label: i18n.t("global_variables_duration_less_3") },
    // { value: '3-12', label: i18n.t("global_variables_duration_3_12") },
    { value: '12>', label: "Plus de 12 mois" }
];


export const PAYMENT_FREQUENCY_OPTIONS = [
    { value: 12, label: i18n.t("global_variables_payment_option_monthly") },
    { value: 4, label: i18n.t("global_variables_payment_option_quarterly") },
    { value: 2, label: i18n.t("global_variables_payment_option_biannual") },
    { value: 1, label: i18n.t("global_variables_payment_option_annual") }
];

export const PAYMENT_CURRENCY_OPTIONS = [
    { value: "USD", label: 'USD' },
    { value: "EUR", label: 'EUR' },
    { value: "MXN", label: 'MXN' }
];

export const SORT_OPTIONS = [
    { value: "high", label: i18n.t("global_variables_payment_option_high")  },
    { value: "low", label: i18n.t("global_variables_payment_option_low")  }
];
